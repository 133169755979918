/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'neos-logo': {
    width: 16,
    height: 16,
    viewBox: '0 0 498.7 140',
    data: '<path pid="0" _fill="#EA5290" d="M325.2 7.1c-34.9 0-63.3 28.4-63.3 63.3 0 11.8 3.3 22.8 8.9 32.3.6 1.1.8 2.4.6 3.7-2.4 13-14.3 22.5-14.5 22.6-.4.3-.6.6-.8.8 0 .1-.1.1-.1.2s-.1.1-.1.2v.1c0 .1 0 .1-.1.2V130.8c0 .5.2.9.6 1.2.1 0 .1.1.2.1.2.1.4.1.6.1h1c.2 0 16-1.2 25.2-2.2 4.1-.4 8.8-1 13.1-1.6 2-.2 3.9.1 5.7.8 7.1 2.8 14.9 4.3 23 4.3 34.9 0 63.3-28.4 63.3-63.3-.1-34.7-28.5-63.1-63.3-63.1z"/><path pid="1" d="M74.9 7.2c-15.9 0-27.2 6.2-33.5 14.4-.1.2-.3.3-.6.3-.4 0-.7-.3-.7-.7L38.8 11c0-.8-.6-1.4-1.4-1.4H8.5c-.8 0-1.5.6-1.5 1.4v118.7c0 .8.6 1.4 1.4 1.4h32.4c.8 0 1.4-.6 1.4-1.4V63.4c.1-3.7 1-7.6 3-11.1 4.4-7.9 12.6-12.3 20.7-12h.5c2.7.2 5.4.8 8 2.1.2.1.4.2.7.3.1 0 .2.1.3.1 6.2 3.7 9.7 11 9.7 20.2V129.7c0 .8.6 1.4 1.4 1.4H119c.8 0 1.4-.6 1.4-1.4V61.1c0-32.8-16-53.9-45.5-53.9zM193.5 7.2c-36.2 0-61.1 27.1-61.1 63.5s24.5 62.8 62.6 62.8c22.1 0 37.9-6.8 50.5-20.6.3-.3.4-.6.4-1.1 0-.5-.3-1-.7-1.2l-21.8-17.7c-.2-.2-.5-.3-.9-.3-.5 0-.9.2-1.1.6-5.4 6.6-14.4 11.3-25.7 11.3-17.3 0-26.8-8.6-27.8-23.8 0-.4.3-.6.7-.7h80.1c.8 0 1.4-.6 1.4-1.4v-9.9c-.1-33.2-18.5-61.5-56.6-61.5zm21 50.6h-46c-.4 0-.7-.3-.7-.7 1-13.5 10.3-23.3 24.4-23.3 14.1 0 22.3 10 23 23.3 0 .3-.3.6-.7.7zM454.7 57.3c-17.3-4.1-21.8-6.5-21.8-12.7 0-6.5 5.5-9.6 15.8-9.6 11.5 0 20.8 4.2 27.7 9.3.2.2.5.3.9.3.5 0 1-.3 1.2-.8l11.8-22.2c.2-.2.3-.5.3-.9 0-.5-.3-1-.7-1.2-9.5-7.6-25-12.3-41.1-12.3-32.1 0-51.1 15.6-51.1 39.8 0 18.9 12.5 28.5 39.1 35 16.3 3.8 20.6 7.2 20.6 13.7 0 7-6.5 10.3-17.3 10.3-12.9 0-23.9-4.7-32-11.1-.2-.2-.6-.4-.9-.4-.6 0-1.1.4-1.3.9L394 117.6c-.2.2-.3.5-.3.9 0 .5.3 1 .7 1.2 10.4 8.4 27.6 13.8 45.5 13.8 32.8 0 53-16.1 53-40.3-.1-18.2-12.6-29.7-38.2-35.9z"/>'
  }
})
