/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pes-logo': {
    width: 602.362,
    height: 744.059,
    viewBox: '0 0 602.362 744.059',
    data: '<path pid="0" d="M17.716 17.72v566.93h141.447L301.17 726.338V584.65h283.476V17.72" _fill="red"/><path pid="1" d="M471.839 59.15c-21.627.03-41.826 7.364-54.446 19.66-9.748 9.725-16.652 22.369-16.637 37.432-.023 15.578 5.543 28.07 14.368 36.865 8.817 8.81 20.803 13.967 33.65 16.069 12.168 2.057 27.753 2.692 39.512 4.915 5.188.938 9.921 2.775 13.234 5.672 3.304 2.934 5.444 6.715 5.482 12.477-.007 3.94-.832 6.64-1.89 8.885-1.051 2.246-2.413 3.993-4.349 6.05-5.74 6.072-16.938 8.734-28.924 8.696-9.982.03-21.28-2.087-27.79-8.696-4.803-4.794-8.523-11.79-8.508-19.661V184.3H396.22v3.214c.023 17.83 8.534 33.54 19.661 43.67 15.396 14.035 36.162 19.276 55.58 19.283 22.407-.015 44.132-5.596 58.606-20.04 9.316-9.263 15.721-22.125 15.691-39.51.023-16.01-5.981-28.842-15.502-38-9.52-9.172-22.421-14.67-36.297-16.636-11.722-1.527-27.594-2.798-38.756-4.537-4.87-.81-8.719-2.503-11.343-5.104-2.609-2.617-4.136-6.201-4.159-11.343.046-5.838 1.71-9.354 5.105-13.045 6.321-6.616 17.173-8.908 26.845-8.885 4.423 0 9.43.923 13.99 2.458 4.56 1.512 8.59 3.735 10.964 6.238 3.811 3.985 6.821 10.345 6.995 15.691l.19 2.836h39.321v-3.025c-.28-15.88-8.076-29.779-19.094-40.267h-.189c-12.877-12.031-32.199-18.126-51.988-18.149zM67.65 62.362v184.89h38.377v-64.087h34.596c21.098.007 37.848-5.899 49.153-16.637 11.313-10.73 17.03-26.089 17.014-43.86.016-17.77-5.694-33-17.014-43.67-11.313-10.67-28.063-16.651-49.153-16.636H67.651zm171.09 0v184.89H372.21v-35.73h-94.903v-42.725h83.56v-35.352h-83.56V97.904h94.903V62.363H238.74zM106.028 96.959h33.462c10.314.008 17.271 2.783 21.74 7.184 4.455 4.408 6.602 10.7 6.617 18.716-.015 8.016-2.17 14.375-6.617 18.716-4.446 4.34-11.418 6.98-21.74 6.994h-33.462V96.96z" _fill="#fff"/>'
  }
})
