/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'spd-logo': {
    width: 500,
    height: 500,
    viewBox: '0 0 340 340',
    data: '<path pid="0" _fill="#E2001A" d="M0 0h340v340H0z"/><path pid="1" d="M87 239l13-20c-72-40-106 47-41 58 32 5 4 32-26 6l-13 22c74 49 122-49 46-56-16-1-17-26 21-10z" _fill="#fff"/><path pid="2" d="M110 210v105h30v-35h23c37 0 38-71 0-70h-53zm30 23h12c13 0 13 25 0 25h-12v-25z" _fill="#fff" fill-rule="evenodd"/><path pid="3" d="M198 210v105h46c59 1 59-105 1-105h-47zm29 25h5c37 0 37 56 0 56h-5v-56z" _fill="#fff" fill-rule="evenodd"/>'
  }
})
