/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'diem25-logo': {
    width: 189,
    height: 39,
    viewBox: '0 0 189 39',
    data: '<defs><linearGradient x1="100%" y1="42.236%" x2="0%" y2="52.139%" id="svgicon_diem25-logo_a"><stop stop-color="#ED1D24" offset="0%"/><stop stop-color="#EC5022" offset="100%"/></linearGradient></defs><g _fill="none" fill-rule="evenodd"><path pid="0" d="M18.356 39l16.858-13.023-16.858-13.023V39zM0 39l16.857-13.023L0 12.954V39z" _fill="url(#svgicon_diem25-logo_a)"/><path pid="1" d="M66.864 19.25c0-7.881-6.556-14.422-14.645-14.422h-7.454v28.845h7.454c8.089 0 14.645-6.541 14.645-14.423zM40.059.162h12.16c10.68 0 19.35 8.579 19.35 19.088 0 10.51-8.67 19.088-19.35 19.088h-12.16V.162zM76.307 13.781h4.546v24.556h-4.546V13.781zm-.74-7.024a2.988 2.988 0 013.013-3.002c1.639 0 2.96 1.34 2.96 3.002 0 1.663-1.321 3.057-2.96 3.057-1.692 0-3.014-1.394-3.014-3.057zM86.934.162h23.791v4.612H91.692v8.953h16.6V18.5h-16.6v15.12h18.557v4.718H86.934V.162zM143.98 38.338V7.884l-12.002 16.62-11.948-16.62v30.454h-4.706V.162h4.97l11.684 16.3 11.685-16.3h5.022v38.176h-4.705zM155.108 38.337V34.31L165 22.575c.376-.556.582-1.146.582-1.77 0-1.667-1.335-3.09-2.978-3.09-1.267 0-2.397.902-2.808 2.152l-.24.729-4.381-.938.24-.902c.855-3.264 3.834-5.486 7.189-5.486 2.02 0 3.868.73 5.306 2.153a7.616 7.616 0 012.191 5.381c0 1.597-.513 3.16-1.438 4.479l-.034.035-7.052 8.436h8.524v4.583h-14.994zM173.97 36.22a6.303 6.303 0 01-.958-1.112l-.514-.729 3.767-2.708.479.694c.787 1.111 2.02 1.84 3.355 1.84 2.19 0 4.074-1.874 4.074-4.027 0-2.256-1.883-4.2-4.074-4.2-.616 0-1.233.208-1.815.52l-.308.139-4.176-1.18 3.15-11.874h11.468V18.2l-7.909-.035-.89 3.299h.48a8.81 8.81 0 016.162 2.5 8.487 8.487 0 011.917 2.847c.411 1.075.65 2.222.65 3.367 0 2.257-.924 4.41-2.567 6.041a9.238 9.238 0 01-2.842 1.875c-1.027.382-2.156.52-3.32.52-2.397 0-4.52-.763-6.128-2.395" _fill="#292F3C"/></g>'
  }
})
