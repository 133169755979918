/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'linke-logo': {
    width: 566.929,
    height: 127.057,
    viewBox: '0 0 566.929 127.057',
    data: '<g fill-rule="evenodd"><path pid="0" d="M547.424 127.057c9.143 0 17.836-7.231 19.285-16.341 1.54-9.687-5.171-16.773-14.602-16.773-8.563 0-17.158 7.52-18.56 16.34-1.472 9.255 4.587 16.774 13.877 16.774zm-80.358-1.592h52.248l3.888-25.737h-24.127l2.512-16.629h21.644l3.888-25.737h-21.646l2.292-15.184h24.127l3.887-25.737h-52.25l-16.463 109.024zm-5.44 0l-16.574-56.392 30.42-52.632h-29.639l-22.45 46.415 6.871-46.415h-28.122l-16.465 109.024h28.125l7.553-50.028 10.092 50.028h30.189zm-160.443 0h27.02l4.826-31.954c1.55-10.269 1.839-20.39 2.7-30.655l11.776 62.61h27.019L390.989 16.44h-27.02l-4.193 27.762c-1.9 12.579-1.71 25.013-2.508 37.594L344.393 16.44h-26.745l-16.465 109.024zm-49.619 0l3.885-25.737h-21.093l12.578-83.287h-28.122l-16.465 109.024h49.217zm-132.04 0h52.248l3.887-25.737h-24.125l2.512-16.629h21.644l3.887-25.737H157.93l2.294-15.184h24.125l3.888-25.737h-52.249l-16.465 109.024zm-11.197 0L124.79 16.441H96.667L80.202 125.465h28.125zm-67.843-81.84c12.82 0 12.537 16.484 10.747 28.341-1.55 10.266-6.29 27.039-19.132 27.182l8.385-55.522zM0 125.466h24.54c17.095 0 47.985-4.626 55.584-54.946 4.977-32.968-9.067-54.078-41.05-54.078H16.466L0 125.465zm289.809 0l13.87-89.067h-28.122l-13.873 89.067h28.125z"/><path pid="1" _fill="#dc0000" d="M309.336 0l-54.314 16.418 49.508 14.55z"/></g>'
  }
})
