/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cdu-logo': {
    width: 750,
    height: 230,
    viewBox: '0 0 750 230',
    data: '<path pid="0" d="M218 64l8-49C-59-55-69 278 201 211l7-49C8 206 59 9 218 64zM259 10l-32 207h144c114 0 155-207 12-207zm62 45h44c61 0 42 117-14 117h-47z" _fill="#ba261d"/><path pid="1" d="M511 10h73l-18 117c-9 52 73 69 86-1l17-116h71l-19 124c-25 127-250 109-226-10z" _fill="#ba261d"/>'
  }
})
