/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'star': {
    width: 182,
    height: 172,
    viewBox: '0 0 182 172',
    data: '<g fill-rule="nonzero" _fill="#FEE872"><path pid="0" d="M90.964 1.784L66.1 78.311l38.374 12.468"/><path pid="1" d="M91.036 1.784L115.9 78.311 77.527 90.779"/><path pid="2" d="M167.627 57.362H87.16v40.35"/><path pid="3" d="M167.649 57.43l-65.098 47.297-23.716-32.643"/><path pid="4" d="M138.394 147.358l-24.865-76.527L75.155 83.3"/><path pid="5" d="M138.336 147.4l-65.098-47.296 23.716-32.643"/><g><path pid="6" d="M43.664 147.4l65.098-47.296-23.716-32.643"/><path pid="7" d="M43.606 147.358l24.865-76.527L106.845 83.3"/></g><g><path pid="8" d="M14.351 57.43l65.098 47.297 23.716-32.643"/><path pid="9" d="M14.373 57.362H94.84v40.35"/></g></g>'
  }
})
